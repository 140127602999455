import React from 'react';
import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

import useStyles from './styles';

import { FontAwesomeIcon } from '$common/fontawesome-pro/react-fontawesome';

export const query = graphql`
    query appStoreLinksQuery {
        site {
            siteMetadata {
                productEthicaHealth {
                    appStoreLink
                    googlePlayLink
                }
            }
        }
    }
`;

const StoreButton = (props) => {
    const { store, size, onClick, url, ...restProps } = props;

    const classes = useStyles();
    const {
        site: {
            siteMetadata: {
                productEthicaHealth: { appStoreLink, googlePlayLink },
            },
        },
    } = useStaticQuery(query);

    const iconClassName = clsx(classes.icon, {
        [classes.iconMedium]: size === 'medium' && store !== 'apple',
        [classes.iconLarge]: size === 'large' && store !== 'apple',
        [classes.appleIconMedium]: size === 'medium' && store === 'apple',
        [classes.appleIconLarge]: size === 'large' && store === 'apple',
    });
    const buttonTitleClassName = clsx(classes.buttonTitle, {
        [classes.buttonTitleMedium]: size === 'medium',
        [classes.buttonTitleLarge]: size === 'large',
    });
    let buttonClasses = {
        root: clsx(classes.root, classes.rootMedium),
        label: clsx(classes.label, classes.labelMedium),
    };
    if (size === 'large') {
        buttonClasses = {
            root: clsx(classes.root, classes.rootLarge),
            label: clsx(classes.label, classes.labelLarge),
        };
    }
    const storeURLs = {
        apple: appStoreLink,
        'google-play': googlePlayLink,
    };
    const calculatedURL = !url && !onClick ? storeURLs[store] : url;

    return (
        <Button
            component={calculatedURL ? 'a' : 'div'}
            href={calculatedURL || undefined}
            target={calculatedURL ? '_blank' : undefined}
            rel={calculatedURL ? 'noopener noreferrer' : undefined}
            variant="outlined"
            classes={buttonClasses}
            onClick={onClick}
            color="secondary"
            {...restProps}
        >
            <FontAwesomeIcon icon={['fab', store]} className={iconClassName} />

            <div className={buttonTitleClassName}>
                <span>
                    {store === 'apple' && 'Download on the'}
                    {store === 'google-play' && 'Get it on'}
                </span>
                {store === 'apple' && 'App Store'}
                {store === 'google-play' && 'Google Play'}
            </div>
        </Button>
    );
};

StoreButton.defaultProps = {
    size: 'medium',
    onClick: undefined,
    url: undefined,
};

StoreButton.propTypes = {
    store: PropTypes.string.isRequired,
    size: PropTypes.string,
    onClick: PropTypes.func,
    url: PropTypes.string,
};

export default StoreButton;
